import { SyntheticEvent } from 'react';

import useClickOutside from '~/src/common/hooks/use-click-outside';
import I18n from '~/src/common/services/I18n';

import ConditionalTooltip from '../../../Tooltip';

import * as S from './layout';

type PickerDebouncedProps = {
  quantity: number;
  onQuantityChange: (currentQuantity: number, increment: number, e?: SyntheticEvent) => void;
  availableQuantity: number;
  onClickOutside: () => void;
  isOpen: boolean;
  isTooltipVisible: boolean;
  onAddQuantity?: () => void;
  onRemoveQuantity?: () => void;
  incrementStep?: number;
};

const PickerContent = ({
  onQuantityChange,
  quantity,
  availableQuantity,
  onClickOutside,
  isOpen,
  onAddQuantity,
  onRemoveQuantity,
  incrementStep = 1,
}: PickerDebouncedProps) => {
  const ref = useClickOutside<HTMLDivElement>(onClickOutside, isOpen);

  const isMaxQuantityReached =
    quantity >= availableQuantity || quantity + incrementStep > availableQuantity;

  const pickerQuantity = isOpen ? quantity : '';

  return (
    <S.PickerButtonsWrapper $isOpen={isOpen}>
      <S.PickerButtonsContainer ref={ref}>
        <S.RemoveQuantityButton
          icon={quantity <= 1 ? 'trash' : 'minus'}
          iconSize={16}
          aria-label={I18n.t('common.remove-product')}
          onClick={(e: SyntheticEvent) => {
            onRemoveQuantity?.();
            onQuantityChange(
              quantity,
              quantity - incrementStep >= 0 ? -incrementStep : -quantity,
              e,
            );
          }}
          disabled={quantity === 0}
        />
        <S.PickerQuantity>{pickerQuantity}</S.PickerQuantity>
        <ConditionalTooltip
          maxWidth={240}
          content={I18n.t('common.picker-sold-out-tooltip')}
          disabled={!isMaxQuantityReached || !isOpen}>
          <S.AddQuantityButton
            icon="plus"
            iconSize={16}
            aria-label={I18n.t('common.add-product')}
            onClick={(e: SyntheticEvent) => {
              onAddQuantity?.();
              onQuantityChange(
                quantity,
                Math.min(incrementStep, Math.max(availableQuantity - incrementStep, 0)),
                e,
              );
            }}
            disabled={isMaxQuantityReached}
          />
        </ConditionalTooltip>
      </S.PickerButtonsContainer>
    </S.PickerButtonsWrapper>
  );
};

export default PickerContent;
